var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();_vm.isFiltered = true; _vm.needToRestShownItems = true; _vm.filter();}}},[_c('v-row',{staticClass:"align-center ma-1 mt-2"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"auto","align-self":"center"}},[_c('v-radio-group',{staticClass:"mt-0",attrs:{"row":"","hide-details":""},model:{value:(_vm.dateType),callback:function ($$v) {_vm.dateType=_vm._n($$v)},expression:"dateType"}},[_c('v-radio',{attrs:{"label":_vm.$t('daily-date'),"value":1}}),_c('v-radio',{attrs:{"label":_vm.$t('from-date-to-date'),"value":2}})],1)],1),(_vm.dateType === 2)?_c('v-col',{staticClass:"pa-1",attrs:{"md":"2","sm":"4","cols":"6"}},[_c('bee-date-picker',{attrs:{"input-label":_vm.$t('inputs.start-date'),"menu-picker":"","dense":"","hide-details":"","input-props":{
                            outlined: true,
                            dense: true,
                            clearable: true,
                        }},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1):_vm._e(),(_vm.dateType === 2)?_c('v-col',{staticClass:"pa-1",attrs:{"cols":"6","sm":"3","md":"2"}},[_c('bee-date-picker',{attrs:{"input-label":_vm.$t('inputs.end-date'),"menu-picker":"","dense":"","hide-details":"","input-props":{
                            outlined: true,
                            dense: true,
                            clearable: true,
                        }},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1):_vm._e(),(_vm.dateType === 1)?_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('bee-date-input',{attrs:{"append-icon":_vm.$i18n.locale === 'en' ? 'mdi-menu-right' : 'mdi-menu-left',"prepend-icon":_vm.$i18n.locale === 'en' ? 'mdi-menu-left' : 'mdi-menu-right',"label":_vm.$t('inputs.date'),"input-format-order":"DD-MM-YYYY","dense":"","loading":_vm.loading,"hide-details":""},on:{"click:append":function($event){_vm.needToRestShownItems = true;
                            _vm.addDate();},"click:prepend":function($event){_vm.needToRestShownItems = true;
                            _vm.subDate();}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1):_vm._e(),_c('v-col',{staticClass:"pa-1",attrs:{"md":"2","sm":"4","cols":"6"}},[_c('v-autocomplete',{staticClass:"classification",attrs:{"items":_vm.classificationVisits,"item-value":"id","item-text":"name","label":_vm.$t('inputs.class'),"dense":"","clearable":"","hide-details":"","disabled":_vm.loading,"outlined":""},model:{value:(_vm.workload),callback:function ($$v) {_vm.workload=$$v},expression:"workload"}})],1),_c('v-col',{staticClass:"pa-1",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"px-sm-4 mx-2",attrs:{"color":"success","loading":_vm.loading,"disabled":_vm.loading,"small":"","type":"submit"}},[_c('v-icon',{staticClass:"me-1"},[_vm._v(" mdi-magnify ")]),_vm._v(" "+_vm._s(_vm.$t('search'))+" ")],1),_c('v-btn',{staticClass:"px-sm-8",attrs:{"disabled":!_vm.isFiltered || _vm.loading,"color":"alert-color white--text","small":""},on:{"click":function($event){_vm.needToRestShownItems = true; _vm.clearFilter(); _vm.filter()}}},[_vm._v(" "+_vm._s(_vm.$t('reset'))+" ")])],1)],1)],1),_c('bee-handy-table',{ref:"table",attrs:{"headers":_vm.headers,"items":[],"fixed-header":"","height":_vm.$vuetify.breakpoint.height - 150,"data-table-props":{
                disableSort: true
            },"loading":_vm.loading,"dense":"","zebra":"","pagination-on-scroll":"","pagination-on-scroll-auto-reset-shown":false,"pagination-on-scroll-server-items":"","items-per-page":20,"options-scroll":_vm.scrollOptions,"server-items-length":_vm.serverItemsLength,"shown-rows-text":_vm.$t('shown-rows')},on:{"update:optionsScroll":function($event){_vm.scrollOptions=$event},"update:options-scroll":function($event){_vm.scrollOptions=$event},"pagination-on-scroll:reach-last-row":_vm.filter}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }