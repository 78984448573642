<template>
    <div>
        <v-form @submit.prevent="isFiltered = true; needToRestShownItems = true; filter();">
            <v-row class="align-center ma-1 mt-2">                
                <v-col cols="auto" class="pa-0" align-self="center">
                    <v-radio-group
                        v-model.number="dateType"
                        row
                        hide-details
                        class="mt-0"
                    >
                        <v-radio
                            :label="$t('daily-date')"
                            :value="1"
                        ></v-radio>
                        <v-radio
                            :label="$t('from-date-to-date')"
                            :value="2"
                        ></v-radio>
                    </v-radio-group>
                </v-col>

                <!-- start date -->
                <v-col v-if="dateType === 2" md="2" sm="4" cols="6" class="pa-1">
                    <bee-date-picker
                        v-model="startDate"
                        :input-label="$t('inputs.start-date')"
                        menu-picker
                        dense
                        hide-details
                        :input-props="{
                            outlined: true,
                            dense: true,
                            clearable: true,
                        }"
                    ></bee-date-picker>
                </v-col>
            
                <!-- end date -->
                <v-col v-if="dateType === 2" cols="6" sm="3" md="2" class="pa-1">
                    <bee-date-picker
                        v-model="endDate"
                        :input-label="$t('inputs.end-date')"
                        menu-picker
                        dense
                        hide-details
                        :input-props="{
                            outlined: true,
                            dense: true,
                            clearable: true,
                        }"
                    ></bee-date-picker>
                </v-col>

                <v-col v-if="dateType === 1" cols="12" sm="6" md="3" class="pa-1">
                    <bee-date-input
                        v-model="date"
                        :append-icon="$i18n.locale === 'en' ? 'mdi-menu-right' : 'mdi-menu-left'"
					    :prepend-icon="$i18n.locale === 'en' ? 'mdi-menu-left' : 'mdi-menu-right'"
                        :label="$t('inputs.date')"
                        input-format-order="DD-MM-YYYY"
                        dense
                        :loading="loading"
                        hide-details
                        @click:append="
                            needToRestShownItems = true;
                            addDate();
                        "
                        @click:prepend="
                            needToRestShownItems = true;
                            subDate();
                        "
                    ></bee-date-input>
                </v-col>

                <!-- classification -->
                <v-col md="2" sm="4" cols="6" class="pa-1">
                    <v-autocomplete
                        class="classification"
                        v-model="workload"
                        :items="classificationVisits"
                        item-value="id"
                        item-text="name"
                        :label="$t('inputs.class')"
                        dense
                        clearable
                        hide-details
                        :disabled="loading"
                        outlined
                    ></v-autocomplete>
                </v-col>
                            
                <!-- search button -->
                <v-col cols="auto" class="pa-1">
                    <v-btn
                        color="success"
                        class="px-sm-4 mx-2"
                        :loading="loading"
                        :disabled="loading"
                        small
                        type="submit"
                    >
                        <v-icon class="me-1"> mdi-magnify </v-icon>
                        {{$t('search')}}
                    </v-btn>
            
                     <v-btn
                        class="px-sm-8"
                        @click="needToRestShownItems = true; clearFilter(); filter()"
                        :disabled="!isFiltered || loading"
                        color="alert-color white--text"
                        small
                    >
                        {{ $t('reset') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
        <bee-handy-table
            ref="table"
            :headers="headers"
            :items="[]"
            fixed-header
            :height="$vuetify.breakpoint.height - 150"
            :data-table-props="{
                disableSort: true
            }"
            :loading="loading"
            dense
            zebra
            pagination-on-scroll
            :pagination-on-scroll-auto-reset-shown="false"
            pagination-on-scroll-server-items
            :items-per-page="20"
            :options-scroll.sync="scrollOptions"
            :server-items-length="serverItemsLength"
            @pagination-on-scroll:reach-last-row="filter"
            :shown-rows-text="$t('shown-rows')"
        ></bee-handy-table>
    </div>
</template>

<script>
import moment from 'moment';
import { classificationVisits } from '@/helpers/enums'
export default {
    data() {
        return {
            workload: null,
            classificationVisits,
            isFiltered: false,
            loading: false,
            dateType: 1,
            startDate: this.moment().startOf('month').format('YYYY-MM-DD'),
            endDate: this.moment().endOf('month').format('YYYY-MM-DD'),
            date: this.moment().format('YYYY-MM-DD'),
            needToRestShownItems: false,
            scrollOptions: {},
            serverItemsLength: 0,
            headers: [
                {
                    text: this.$t('headers.core-message'),
                    value: 'coreMessage',
                    align: 'center'
                },
                { 
                    text: this.$t('headers.objection'), 
                    value: 'objections', 
                    setValueExpr: val => val ? val.length > 20 ? val.slice(0, 20) + '...' : val : '-',
                    align: 'start', 
                    noNotAvailableText: true
                },
                { 
                    text: this.$t('headers.next-call'), 
                    value: 'purpose', 
                    setValueExpr: val => val ? val.length > 20 ? val.slice(0, 20) + '...' : val : '-',
                    align: 'start',
                    noNotAvailableText: true
                },
                { 
                    text: this.$t('headers.notes'), 
                    value: 'notes', 
                    setValueExpr: val => val ? val.length > 20 ? val.slice(0, 20) + '...' : val : '-',
                    align: 'center', 
                    noNotAvailableText: true
                }
            ]
        }
    },

    methods: {
        moment,
        filter(newOptionsScroll, isFirstLoad = false) {
            this.loading = true;
            let page = 1;

            if (newOptionsScroll) {
                page = newOptionsScroll.page;
            }

            const { itemsPerPage } = this.scrollOptions;
            return this.$store.dispatch('visits/fetchByFilter', {
                fromDate: this.dateType === 1 ? this.date : this.startDate, 
                toDate: this.dateType === 1 ? this.date : this.endDate,
                regionIds: [],
                classification: this.workload
            })
            .then(({ data, total }) => {
                if (this.needToRestShownItems) {
                    this.$refs.table.resetShownRows();
                    this.needToRestShownItems = false;
                }
                this.totalVisits = total;
                this.$refs.table.addScrollItems(data);
                this.serverItemsLength = this.totalVisits;
            })
            .finally(() => {
                if (!isFirstLoad) this.loading = false;
            })
        },
        clearFilter() {
            this.dateType = 1
            this.workload = null
        }
    },
    created() {
        this.filter(true)
    }
}
</script>
